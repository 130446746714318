@charset "utf-8";

#app {
  background: #fff;
  color: #000;
  word-break: break-word;
}

#app .wrap {
  margin: 0 auto;
  padding: 0;
  width: 800px;
  overflow: auto;
}

@media (max-width: 1000px) {
  body {
    min-width: 360px;
  }

  #app .wrap {
    padding: 20px 30px;
    width: auto;
  }
}

@media (max-width: 360px) {
  #app .wrap {
    padding: 10px 20px;
    width: auto;
  }
}

#hd {
  padding: 20px 0;
  width: 100%;
  overflow: auto;
  font-size: 0.9em;
}

#hd nav {
  display: block;
}

#hd-logo {
  width: 100px;
  height: 25px;
  display: block;
  background: url("./nirui.png") center center no-repeat;
  background-size: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  float: left;
  position: relative;
  margin-top: 7px;
  display: block;
  line-height: 100px;
}

#hd-nav {
  float: right;
  margin-top: 2px;
}

#hd-nav a {
  color: #000;
  margin-left: 10px;
  padding: 2px;
  text-transform: uppercase;
  font-size: 0.98em;
  font-family: "Open Sans Condensed", Arial, Helvetica, sans-serif;
  font-weight: bold;
  display: inline-block;
}

@media (max-width: 360px) {
  #hd-nav a {
    margin-left: 5px;
  }
}

#hd-nav a > span {
  padding: 2px 10px;
  display: inline-block;
}

#hd-nav a > span > span {
  display: inline-block;
}

#hd-nav a.hlight {
  background: #c5d linear-gradient(to right bottom, #c5d 0, #28b 100%);
}

#hd-nav a.hlight > span {
  background: #fff;
}

#hd-nav a.hlight > span > span {
  background: #c5d linear-gradient(to right bottom, #c5d 0, #28b 100%);
  background-clip: text;
  color: transparent;
}

@media (max-width: 768px) {
  body {
    font-size: 1.3em;
  }
}

@media (max-width: 480px) {
  body {
    font-size: 1.15em;
  }
}

@media (max-width: 360px) {
  body {
    font-size: 1.05em;
  }
}

#ft {
  font-size: 0.9em;
  background: #000;
  color: #fff;
  padding: 34px 0 50px 0;
}

#ft a {
  color: #fff;
}
