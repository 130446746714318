@charset "utf-8";

#contact {
}

#contact-methods {
  margin: 20px 0;
}

#contact-methods > li {
  margin-bottom: 10px;
  font-size: 0.9em;
  color: #555;
}

#contact-methods > li > h2 {
  font-size: 1.5em;
  color: #000;
}
