@charset "utf-8";

#home {
  padding: 0 0 30px 0;
}

#home .home-subsection {
  padding: 30px;
}

#home .home-subsection h1 {
  margin: 0 20px 20px 0;
  font-size: 1.5em;
  font-family: "Open Sans Condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  text-transform: uppercase;
  overflow: hidden;
}

#home-welcome {
  width: 100%;
  overflow: auto;
}

#home-welcome-title {
  margin: 80px 20px 80px 20px;
  float: left;
  font-size: 1.2em;
  font-family: "Open Sans Condensed", Arial, Helvetica, sans-serif;
}

#home-welcome-title > h1 {
  padding: 5px;
  margin: 0;
  color: #000;
  font-weight: normal;
  font-size: 3em;
  overflow: hidden;
  display: inline-block;
}

#home-welcome-title > h1 > strong {
  font-weight: bold;
}

#home-welcome-title > p {
  font-size: 1.1em;
  color: #000;
  padding: 5px;
}

#home-welcome-portrait {
  background: #eee;
  overflow: auto;
}

#home-welcome-portrait-image {
  overflow: auto;
  padding: 56.25% 0 0 0;
}

#home-about {
  background: #eee;
  color: #000;
}

#home-about p {
  font-size: 0.9em;
  margin-bottom: 0.65em;
}

#home-feed {
}

#home-feed > .home-feed-item {
  width: 100%;
  overflow: auto;
  margin-bottom: 20px;
}

#home-feed > .home-feed-item:last-child {
  margin-bottom: 0;
}

#home-feed > .home-feed-item > h2 {
  color: #000;
  font-weight: bold;
  float: left;
  text-align: center;
  transform: rotate(90deg);
  font-size: 2em;
  padding: 1em 0;
}

@media (max-width: 768px) {
  #home-feed > .home-feed-item > h2 {
    transform: none;
    float: none;
    text-align: left;
    padding: 0.5em 0;
  }
}

#home-feed > .home-feed-item > .home-feed-list {
  margin-left: 10px;
  padding-left: 2px;
  background: #c5d linear-gradient(to bottom, #c5d 0, #28b 90%);
  overflow: auto;
  position: relative;
}

#home-feed > .home-feed-item > .home-feed-list:after,
#home-feed > .home-feed-item > .home-feed-list:before {
  content: " ";
  width: 10px;
  height: 10px;
  background: #c5d;
  display: block;
  position: absolute;
  left: -5px;
  top: 39px;
  transform: rotate(45deg);
}

#home-feed > .home-feed-item > .home-feed-list:after {
  background: #fff;
  left: -8px;
}

#home-feed > .home-feed-item > .home-feed-list > .home-feed-list-content {
  background: #fff;
  padding-left: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
}

#home-feed > .home-feed-item > .home-feed-list > .home-feed-list-content > ul {
}

#home-feed
  > .home-feed-item
  > .home-feed-list
  > .home-feed-list-content
  > ul
  > li {
  margin-top: 0;
  margin-bottom: 20px;
}

#home-feed
  > .home-feed-item
  > .home-feed-list
  > .home-feed-list-content
  > ul
  > li:last-child {
  margin-bottom: 0;
}

#home-feed
  > .home-feed-item
  > .home-feed-list
  > .home-feed-list-content
  .home-feed-content {
}

#home-feed
  > .home-feed-item
  > .home-feed-list
  > .home-feed-list-content
  .home-feed-content
  > .hfc-title {
  font-weight: bold;
}

#home-feed
  > .home-feed-item
  > .home-feed-list
  > .home-feed-list-content
  .home-feed-content
  > .hfc-attch
  > .hfc-attch-image {
  margin: 10px 5px;
}

#home-feed
  > .home-feed-item
  > .home-feed-list
  > .home-feed-list-content
  .home-feed-content
  > .hfc-attch
  > .hfc-attch-image
  > a {
  background: #fff;
  padding: 5px;
  box-shadow: 0 0 5px #0006;
  display: inline-block;
}

#home-feed
  > .home-feed-item
  > .home-feed-list
  > .home-feed-list-content
  .home-feed-content
  > .hfc-attch
  > .hfc-attch-image
  > a
  > img {
  vertical-align: middle;
  max-width: 300px;
  max-height: 300px;
  width: 100%;
}

@media (max-width: 480px) {
  #home .wrap {
    padding: 0;
  }

  #home-feed
    > .home-feed-item
    > .home-feed-list
    > .home-feed-list-content
    .eventbox1
    .eventbox2
    > .eb2-title {
    float: none;
  }

  #home-feed
    > .home-feed-item
    > .home-feed-list
    > .home-feed-list-content
    .home-feed-content {
    margin-bottom: 10px;
  }
}

#home-contact {
  margin: 20px 0 50px 0;
}

#home-contact > h1#home-contact-link {
  text-align: center;
  padding: 10px;
  margin-bottom: 0;
  font-size: 1.3em;
}

#home-contact > h1#home-contact-link > a {
  color: #000;
  margin-left: 10px;
  padding: 2px;
  text-transform: uppercase;
  font-size: 0.98em;
  font-family: "Open Sans Condensed", Arial, Helvetica, sans-serif;
  font-weight: bold;
  display: inline-block;
  background: #c5d linear-gradient(to right bottom, #c5d 0, #28b 100%);
}

#home-contact > h1#home-contact-link > a > span {
  padding: 5px 15px;
  display: inline-block;
  background: #fff;
}

#home-contact > h1#home-contact-link > a > span > span {
  display: inline-block;
  background: #c5d linear-gradient(to right bottom, #c5d 0, #28b 100%);
  background-clip: text;
  color: transparent;
}

#home-contact > h1#home-contact-link > a > span > span:after {
  content: "\02192";
  margin-left: 5px;
}
