@charset "utf-8";

@import "~normalize.css";
@import "~typeface-open-sans";
@import "~typeface-open-sans-condensed";

* {
  margin: 0;
  padding: 0;
  font-size: inherit;
}

body {
  min-width: 1000px;
}

body {
  margin: 0;
  padding: 0;
  background: #000;
  color: #fff;
}

body {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 1em;
  line-height: 1.5;
}

main {
  display: block;
}

a {
  color: #000;
  text-decoration: none;
}

/* Page */
.subpage {
  margin: 30px 0 100px 0;
}

.subpage .sp-content {
}

.subpage h1.sp-title {
  text-transform: uppercase;
  font-size: 2.5em;
  font-family: "Open Sans Condensed", Arial, Helvetica, sans-serif;
  font-weight: bold;
  margin: 0 0 30px 0;
  overflow: hidden;
  position: relative;
  color: #000;
}

.subpage h1.sp-title:after {
  content: " ";
  display: block;
  position: absolute;
  background: #c5d linear-gradient(to right, #c5d 0, #28b 100%);
  bottom: 0;
  height: 3px;
  width: 3em;
}

/* Lists */
.lst-nostyle {
  margin: 0;
  padding: 0;
  list-style: none;
}

.lst1 {
  width: 100%;
  overflow: auto;
}

.lst1 > li {
  margin: 5px 0;
}

.hlst1 {
  width: 100%;
  overflow: auto;
}

.hlst1 > li {
  margin: 0 5px 5px 5px;
  float: left;
}

.hlst2 {
  width: 100%;
  overflow: auto;
}

.hlst2 > li {
  width: 50%;
  float: left;
}

/* Box */
.eventbox1 {
}

.eventbox1 > .eb1-title {
  font-weight: bold;
  margin-bottom: 5px;
}

.eventbox2 {
  width: 100%;
  overflow: auto;
}

.eventbox2 > .eb2-title {
  width: 60px;
  display: block;
  margin-right: 10px;
  color: #999;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: keep-all;
}

.eventbox2 > .eb2-content {
  display: block;
  overflow: auto;
}
