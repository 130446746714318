@charset "utf-8";

#project {
}

#project-list {
}

#project-list > li {
  width: 50%;
}

@media (max-width: 768px) {
  #project-list > li {
    width: 100%;
  }
}

#project-list > li:nth-child(even) .project {
  margin-right: 0;
}

#project-list .project {
  margin: 20px 20px 20px 0;
}

#project-list .project > .ptitle {
  font-size: 1.6em;
  margin-bottom: 10px;
}

#project-list .project > .ptitle a:after {
  content: "\02924";
  margin-left: 0.3em;
  background: #c5d linear-gradient(to right, #c5d 0, #28b 100%);
  background-clip: text;
  color: transparent;
}

#project-list .project > .pdescription {
  color: #555;
  margin-bottom: 10px;
  font-size: 1em;
  line-height: 1.5em;
  height: 3em;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

#project-list .project > .pdescription:after {
  content: " ";
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 50%;
  width: 50%;
  content: "";
  background: linear-gradient(-45deg, #ffff 10%, #fff0);
  pointer-events: none;
}

#project-list .project > .pinfo {
  font-size: 0.8em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 5px 1px;
}

#project-list .project > .pinfo > .piitem {
  margin-right: 10px;
}

#project-list .project > .pinfo > a {
  color: #000;
  padding: 3px 7px;
  border: 2px solid #000;
  text-transform: uppercase;
  font-family: "Open Sans Condensed", Arial, Helvetica, sans-serif;
  font-weight: bold;
}

#project-list .project > .pinfo > a.piitem.pto:after {
  content: "\029C1";
  margin-left: 0.3em;
  font-weight: bold;
}
